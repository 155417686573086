import { FormikHelpers } from 'formik/dist/types'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

import { SIGN_IN_ERROR_MESSAGE } from '@/configs/messages'
import { PATHS } from '@/configs/paths'
import {
  EMAIL_URL_PARAM_NAME,
  ERROR_URL_PARAM_NAME,
  GO_TO_CHECKOUT_ON_ERROR_URL_PARAM_NAME
} from '@/configs/urlParams'
import {
  useClearStorages,
  useLogInRedirectCallback,
  usePushRouterPath
} from '@/hooks'
import {
  fireInspectletIdentifyingUserEvent,
  fireInspectletResetSessionEvent
} from '@/utils/analytics'
import { authenticate } from '@/utils/auth'
import { showError } from '@/utils/notifications'

interface IUseSigninValues {
  email: string
  password: string
}

export const useSignin = () => {
  const router = useRouter()

  const pushRouterPath = usePushRouterPath()

  const clearStorages = useClearStorages()

  const { successCallback } = useLogInRedirectCallback()

  const emailFromUrl = router?.query?.[EMAIL_URL_PARAM_NAME]?.toString()

  const shouldRedirectToCheckoutOnError =
    router?.query?.[GO_TO_CHECKOUT_ON_ERROR_URL_PARAM_NAME] === 'true'

  const initialValues: IUseSigninValues = useMemo(
    () => ({
      email: emailFromUrl ?? '',
      password: ''
    }),
    [emailFromUrl]
  )

  const errorCallback = useCallback(() => {
    if (shouldRedirectToCheckoutOnError) {
      pushRouterPath(
        PATHS.CHECKOUT.START +
          `?${ERROR_URL_PARAM_NAME}=${encodeURIComponent(
            'error: user already a Suvie member'
          )}`
      )
    } else {
      showError(SIGN_IN_ERROR_MESSAGE)
    }
  }, [pushRouterPath, shouldRedirectToCheckoutOnError])

  const handleSubmit = useCallback(
    async (
      values: IUseSigninValues,
      actions: FormikHelpers<IUseSigninValues>
    ) => {
      try {
        await authenticate({
          email: values?.email,
          password: values?.password
        })

        await clearStorages()

        fireInspectletResetSessionEvent()
        fireInspectletIdentifyingUserEvent(values?.email)

        successCallback()
      } catch (error) {
        errorCallback()

        actions.setSubmitting(false)
      }
    },
    [clearStorages, errorCallback, successCallback]
  )

  return { initialValues, handleSubmit }
}
