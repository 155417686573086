import { useApolloClient } from '@apollo/client'

export const useClearStorages = () => {
  const apolloClient = useApolloClient()

  return async () => {
    await apolloClient.clearStore()
    window?.sessionStorage?.clear?.()
    // Do not clear local storage here, we need to preserve site-version and product-sku values
  }
}
