import { IS_PROD } from '@/configs/env'
import { ISpreeOrderLineItem } from '@/types'
import { convertCentsToPrice } from '@/utils/misc'

export const firePurchaseEvent = ({
  email,
  orderNumber,
  totalCents,
  taxTotalCents,
  shipmentsTotalCents,
  coupons,
  currency,
  userId,
  lineItems
}: {
  email: string
  orderNumber: string
  totalCents: number
  taxTotalCents: number
  shipmentsTotalCents: number
  coupons: string
  currency: string
  userId: number
  lineItems: ISpreeOrderLineItem[]
}): void => {
  fireGoogleAnalyticsPurchaseEvent({
    email,
    orderNumber,
    totalCents,
    taxTotalCents,
    shipmentsTotalCents
  })

  fireNorthbeamPurchaseEvent({
    orderNumber,
    totalCents,
    taxTotalCents,
    shipmentsTotalCents,
    coupons,
    currency,
    userId,
    lineItems
  })
}

export const fireEmailCaptureEvent = (email: string): void => {
  fireInspectletEmailCaptureEvent(email)
  fireNorthbeamEmailCaptureEvent(email)
}

export const fireGoogleAnalyticsPurchaseEvent = ({
  email,
  orderNumber,
  totalCents,
  taxTotalCents,
  shipmentsTotalCents
}: {
  email: string
  orderNumber: string
  totalCents: number
  taxTotalCents: number
  shipmentsTotalCents: number
}): void => {
  if (typeof window === 'undefined' || !IS_PROD) return

  window.dataLayer = window?.dataLayer ?? []

  window.dataLayer?.push({
    event: 'Purchase',
    transactionEmail: email,
    transactionAffiliation: 'Suvie',
    transactionId: orderNumber,
    transactionTotal: convertCentsToPrice({
      cents: totalCents,
      precision: 2
    }),
    transactionTax: convertCentsToPrice({
      cents: taxTotalCents,
      precision: 2
    }),
    transactionShipping: convertCentsToPrice({
      cents: shipmentsTotalCents,
      precision: 2
    })
  })
}

export const fireNorthbeamPurchaseEvent = ({
  orderNumber,
  totalCents,
  taxTotalCents,
  shipmentsTotalCents,
  coupons,
  currency,
  userId,
  lineItems
}: {
  orderNumber: string
  totalCents: number
  taxTotalCents: number
  shipmentsTotalCents: number
  coupons: string
  currency: string
  userId: number
  lineItems: ISpreeOrderLineItem[]
}): void => {
  if (typeof window === 'undefined' || !IS_PROD) return

  window?.Northbeam?.firePurchaseEvent({
    id: orderNumber,
    totalPrice: totalCents / 100,
    taxPrice: taxTotalCents / 100,
    shippingPrice: shipmentsTotalCents / 100,
    coupons,
    currency,
    customerId: userId?.toString(),
    lineItems: lineItems?.map((lineItem) => ({
      productId: lineItem?.variant?.product?.id?.toString(),
      variantId: lineItem?.variant?.id?.toString(),
      productName: lineItem?.variant?.product?.name,
      variantName: lineItem?.variant?.name,
      price: lineItem?.price / 100,
      quantity: lineItem?.quantity
    }))
  })
}

export const fireInspectletResetSessionEvent = (): void => {
  if (typeof window === 'undefined' || !IS_PROD) return

  window?.__insp?.push(['resetSession'])
}

export const fireInspectletIdentifyingUserEvent = (email: string): void => {
  if (typeof window === 'undefined' || !IS_PROD) return

  window?.__insp?.push(['identify', email])
}

export const fireInspectletEmailCaptureEvent = (email: string): void => {
  if (typeof window === 'undefined' || !IS_PROD) return

  window?.__insp?.push(['tagSession', { email }])
}

export const fireNorthbeamEmailCaptureEvent = (email: string): void => {
  if (typeof window === 'undefined' || !IS_PROD) return

  window?.Northbeam?.fireEmailCaptureEvent(email, '')
}

export const fireNorthbeamStartingPurchaseApplianceGoal = (): void => {
  if (typeof window === 'undefined' || !IS_PROD) return

  window?.Northbeam?.fireCustomGoal('appliance')
}

export const fireNorthbeamMealGoal = (): void => {
  if (typeof window === 'undefined' || !IS_PROD) return

  window?.Northbeam?.fireCustomGoal('meal')
}

export const fireGoogleAnalyticsCheckoutEvent = ({
  productSKU,
  siteVersion
}: {
  productSKU: string
  siteVersion?: string
}): void => {
  if (typeof window === 'undefined' || !IS_PROD) return

  window.dataLayer?.push({
    event: 'Checkout',
    productSKU,
    siteVersion
  })
}
