import { GetServerSideProps } from 'next'
import { memo } from 'react'

import AuthLayout from '@/components/AuthLayout'
import PageTitle from '@/components/PageTitle'
import SigninForm from '@/components/SigninForm'
import SiteHead from '@/components/SiteHead'
import { getServerSidePropsForAuthPage } from '@/utils/pages'

const SigninPage = () => (
  <>
    <SiteHead title="Signin" />
    <AuthLayout>
      <PageTitle className="mb-6 font-normal">Sign In</PageTitle>
      <SigninForm />
    </AuthLayout>
  </>
)

export const getServerSideProps: GetServerSideProps = async (context) =>
  getServerSidePropsForAuthPage(context)

export default memo(SigninPage)
