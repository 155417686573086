import { Form, Formik } from 'formik'
import { memo } from 'react'
import * as Yup from 'yup'

import Button from '@/components/Button'
import FormField from '@/components/FormField'
import FormFieldError from '@/components/FormFieldError'
import { useSignin } from '@/hooks'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required')
})

const SigninForm = () => {
  const { initialValues, handleSubmit } = useSignin()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <div className="mb-4 text-left">
            <FormField
              data-cy="sign-in-form-email-input"
              name="email"
              type="email"
              placeholder="Email"
            />
            <FormFieldError name="email" />
          </div>

          <div className="mb-4 text-left">
            <FormField
              data-cy="sign-in-form-password-input"
              name="password"
              type="password"
              placeholder="Password"
            />
            <FormFieldError name="password" />
          </div>

          <Button
            data-cy="sign-in-form-submit-button"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            className="py-2 mb-4 w-full text-sm min-h-[2.5rem]"
          >
            Sign in
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default memo(SigninForm)
