export const EMAIL_URL_PARAM_NAME = 'email'
export const ERROR_URL_PARAM_NAME = 'error'
export const TOKEN_URL_PARAM_NAME = 'token'
export const ACCESS_TOKEN_URL_PARAM_NAME = 'access_token'
export const EXISTED_TOKEN_URL_PARAM_NAME = 'existed_token'
export const LOGIN_TOKEN_URL_PARAM_NAME = 'login_token'
export const ORDER_ID_URL_PARAM_NAME = 'order_id'
export const ORDER_NUMBER_URL_PARAM_NAME = 'order_number'
export const TOTAL_CENTS_URL_PARAM_NAME = 'total_cents'
export const TAX_TOTAL_CENTS_URL_PARAM_NAME = 'tax_total_cents'
export const SHIPMENT_TOTAL_CENTS_URL_PARAM_NAME = 'shipments_total_cents'
export const USER_ID_URL_PARAM_NAME = 'user_id'
export const CHECKOUT_TOKEN_URL_PARAM_NAME = 'checkout_token'
export const IS_SUCCESS_CALLBACK_URL_PARAM_NAME = 'is_success_callback_url'
export const TEST_MODE_SECRET_URL_PARAM_NAME = 'test-mode-secret'
export const SITE_VERSION_URL_PARAM_NAME = 'site-version'
export const PRODUCT_SKU_URL_PARAM_NAME = 'product-sku'
export const GO_TO_TRADE_UP_CHECKOUT_ON_SUCCESS_URL_PARAM_NAME =
  'go_to_trade_up_checkout_on_success'
export const GO_TO_CHECKOUT_ON_SUCCESS_URL_PARAM_NAME =
  'go_to_checkout_on_success'
export const GO_TO_CHECKOUT_ON_ERROR_URL_PARAM_NAME = 'go_to_checkout_on_error'
