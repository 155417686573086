import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { IS_DEV, MAIN_DOMAIN } from '@/configs/env'
import { PATHS } from '@/configs/paths'
import {
  GO_TO_CHECKOUT_ON_SUCCESS_URL_PARAM_NAME,
  GO_TO_TRADE_UP_CHECKOUT_ON_SUCCESS_URL_PARAM_NAME,
  IS_SUCCESS_CALLBACK_URL_PARAM_NAME
} from '@/configs/urlParams'
import { usePushRouterPath } from '@/hooks'
import { checkIfUrlEndsWithDomain } from '@/utils/urls'

export const useLogInRedirectCallback = () => {
  const router = useRouter()

  const pushRouterPath = usePushRouterPath()

  const shouldRedirectToSuccessPageOnSuccess =
    router?.query?.[IS_SUCCESS_CALLBACK_URL_PARAM_NAME]?.toString() === 'true'

  const shouldRedirectToTradeUpCheckoutOnSuccess =
    router?.query?.[GO_TO_TRADE_UP_CHECKOUT_ON_SUCCESS_URL_PARAM_NAME] ===
    'true'

  const shouldRedirectToCheckoutOnSuccess =
    router?.query?.[GO_TO_CHECKOUT_ON_SUCCESS_URL_PARAM_NAME] === 'true'

  const successCallback = useCallback(
    (targetUrl?: string) => {
      let path = PATHS.BASE

      const isTargetUrlValid = checkIfUrlEndsWithDomain(
        targetUrl,
        IS_DEV ? 'localhost' : MAIN_DOMAIN
      )

      if (isTargetUrlValid) {
        path = targetUrl
      } else if (shouldRedirectToSuccessPageOnSuccess) {
        path = PATHS.SUCCESS
      } else if (shouldRedirectToTradeUpCheckoutOnSuccess) {
        path = PATHS.TRADE_UP.CHECKOUT
      } else if (shouldRedirectToCheckoutOnSuccess) {
        path = PATHS.CHECKOUT.START
      }

      pushRouterPath(path)
    },
    [
      pushRouterPath,
      shouldRedirectToCheckoutOnSuccess,
      shouldRedirectToSuccessPageOnSuccess,
      shouldRedirectToTradeUpCheckoutOnSuccess
    ]
  )

  return { successCallback }
}
