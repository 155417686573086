export const checkIfUrlValid = (string: string): boolean => {
  try {
    return Boolean(new URL(string))
  } catch (error) {
    return false
  }
}

export const checkIfUrlEndsWithDomain = (
  url: string,
  domain: string
): boolean => {
  if (!checkIfUrlValid(url)) return false

  const urlObject = new URL(url)

  return urlObject.hostname.endsWith(domain)
}
